import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
      <div className='body_bg'>


        <div className='heading_bg '>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-none d-md-block d-lg-block d-xl-block'>
              <img src="/images/badge1.png" alt="" />
              <h2 className='text-white'>Play for Fun <br />
                with <span className='heading_h2'>Gold Coin!</span></h2>
            </div>
            <div className='luckland_img'>
              <img src="/images/Luckyland-2.png" width={300} height={200} />
            </div>
            <div className='d-none d-md-block d-lg-block d-xl-block'> 
              <h2 className='text-white'>Play with <br /><span className='heading_prizes'>Sweeps Coins<sup>tm</sup></span> <br /> for prizes!</h2>
              <img src="./images/badge2.png " width={100} height={100} />
            </div>
          </div>
        </div>

        <div className='text-center text-white '>
          <h1 className='enter_info  '>Enter your information to</h1>
          <p className='para'>This offer is only available for a limited time. Please allow 4-6 weeks to receive your items.</p>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='col-lg-5 col-md-5 text-white d-none'>
              <img className='lady_img ' src="/images/lady.png" />
            </div>
            <div className='col-lg-7 col-md-7 text-white'>
              <form>
                <div className='d-flex form_data mt-4 p-5'>
                  <div className=''>
                    <label>First Name</label> <br />
                    <input className="inuput" type="text" />
                  </div>
                  <div className='px-2'>
                    <label>Last Name</label> <br />
                    <input type="text" />
                  </div>
                  <div className=''>
                    <label>Email</label> <br />
                    <input type="text" />
                  </div>

                  <div className='px-2'>
                    <label>Phone</label> <br />
                    <input type="text" />
                  </div>
                  <div className=''>
                    <label>Address</label> <br />
                    <input type="text" />
                  </div>
                  <div className='px-2'>
                    <label>Apartment, suite, etc</label> <br />
                    <input type="text" />
                  </div>
                  <div className=''>
                    <label>City</label> <br />
                    <input type="text" />
                  </div>
                  <div className='px-2'>
                    <label>Province</label> <br />
                    <input type="text" />
                  </div>
                  <div className=''>
                    <label>ZIP/postal code</label> <br />
                    <input type="text" />
                  </div>
                  <div className='px-2'>
                    <label>Hat</label> <br />
                    <input type="text" />
                  </div><br />

                </div>
                <div className=''>

                  <button className='ml-5 submit_but'>Submit</button>
                </div>

              </form>
            </div>
          </div>
        </div>


        <div>
          <div className='diamond_duck img-fluid'> hello</div>

          <div className='container all_details'>
            <p>In accordance with clause 3.1(j) of the LuckyLand Slots Terms of Service, you must not sell or trade for value, or seek to sell or trade for value, or accept as a
              sale or trade for value, any Merchandise provided to you by LuckyLand Slots. Doing so is a breach of the warranties you have provided and places your account at risk of permanent deactivation.</p>
            <p>If you have any questions, please contact us at support@luckylandslots.com.</p>
            <p>VGW Group. No purchase necessary. Void where prohibited by law. 18+</p>
            <p>Postal Alternative Method of Entry:</p>
            <p>Receive free Sweeps Coins by obtaining a Postal Request Code and sending a handwritten request that meets the requirements specified in our Sweeps Rules to:</p>
            <p>VGW LUCKYLAND INC.</p>
            <p>LUCKYLAND SLOTS SWEEPSTAKES DEPARTMENT
              PO BOX #8486
              PORTSMOUTH, NH 03801</p>
            <p>For more details see our Sweeps Rules.

              Terms of Service

              © 2023 VGW US Inc. :: 1840 Folsom Street, Suite 302, Boulder, CO, 80302:: All rights reserved</p>

          </div>
          <div className='last_part d-none'>
            <h2 className='text-white'>Luckyland : part of VGW family</h2>
            <img src="./images/logos.png" className="alllogos"/>
          </div>


        </div>

      </div>
      {/* <footer className='text-black text-center footer_div '>
  <p>this is footer</p>
</footer> */}


    </>
  );
}

export default App;
