import React from 'react'
import './App.css';
const Landlord = () => {
  return (
    <>
      <div className='body_bg'>
        <div className='heading_bg'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-none d-md-block d-lg-block d-xl-block'>
              <img src="/images/badge1.png" alt="" />
              <h2 className='text-white heading_header'>Play for Fun <br />
                with <span className='heading_h2'>Gold Coin!</span></h2>
            </div>
            <div className='something '>
              <img src="/images/Luckyland-2.png" className='luckland_img ' width={300} height={200} />
            </div>
            <div className='d-none d-md-block d-lg-block d-xl-block'>
              <h2 className='text-white'>Play with <br /><span className='heading_prizes'>Sweeps Coins<sup>tm</sup></span> <br /> for prizes!</h2>
              <img src="./images/badge2.png " width={100} height={100} />
            </div>
          </div>
        </div>

        <div className='text-center text-white '>
          <h1 className='enter_info  '>Enter your information to receive your FREE Diamond Duck merchandise!</h1>
          <p className='para'>This offer is only available for a limited time. Please allow 4-6 weeks to receive your items.</p>
        </div>
        
        <div className=''>
        <div className='row '>
          <div className='col-lg-5 col-sm-12 col-md-4 text-white  relative'>
            <img className='lady_img ' src="/images/lady.png" />
            <img className='duck d-none d-md-block d-lg-block d-xl-block d--block' src="/images/duck.png" />
          </div>
          <div className='col-lg-7 col-sm-12 col-md-8 text-white  '>
          <div >
            <form className='p-5 form_data1  '>
            
              <div className='p-1'>
                <label className='mylabel'>First Name</label> <br />
                <input className="inuput" type="text" />
              </div>
              <div className='p-1'>
                <label className='mylabel'>Last Name</label> <br />
                <input type="text" />
              </div>
              <div className='p-1'>
                <label className='mylabel'>Email</label> <br />
                <input type="text" />
              </div>

              <div className='p-1'>
                <label className='mylabel'>Address</label> <br />
                <input type="text" />
              </div>
              <div className='p-1'>
                <label className='mylabel'>Apartment, suite, etc</label> <br />
                <input type="text" />
              </div>
              <div className='p-1'>
                <label className='mylabel'>City</label> <br />
                <input type="text" />
              </div>
              <div className='p-1'>
                <label className='mylabel'>Province</label> <br />
                <input type="text" />
              </div>
              <div className='p-1'>
                <label className='mylabel'>ZIP/postal code</label> <br />
                <input type="text" />
              </div>
              <div className='p-1'>
                <label className='mylabel'>Hat</label> <br />
                <input type="text" />
              </div>
              <br />
            

   
               <img src="./images/btn.png " width ={150} height={60} />
                {/* <button className=' submit_but '>Submit</button> */}
            
           
            </form>
            </div>
          </div>
        </div>
        </div>


        <div>
          <div className=' img-fluid'>
            <img src="./images/win-pile.png" className="diamond_duck" />
          </div>
         <div className='playnow'>

            <img className='playnow_img'  src="./images/play.png"/>
         </div>
            <img  className="line" src="./images/line.png"/>
          

          <div className='container all_details'>
            <p>In accordance with clause 3.1(j) of the LuckyLand Slots Terms of Service, you must not sell or trade for value, or seek to sell or trade for value, or accept as a
              sale or trade for value, any Merchandise provided to you by LuckyLand Slots. Doing so is a breach of the warranties you have provided and places your account at risk of permanent deactivation.</p>
            <p>If you have any questions, please contact us at support@luckylandslots.com.</p>
            <p>VGW Group. No purchase necessary. Void where prohibited by law. 18+</p>
            <p>Postal Alternative Method of Entry:</p>
            <p>Receive free Sweeps Coins by obtaining a Postal Request Code and sending a handwritten request that meets the requirements specified in our Sweeps Rules to:</p>
            <p>VGW LUCKYLAND INC.</p>
            <p>LUCKYLAND SLOTS SWEEPSTAKES DEPARTMENT
              PO BOX #8486
              PORTSMOUTH, NH 03801</p>
            <p>For more details see our Sweeps Rules.

              Terms of Service

              © 2023 VGW US Inc. :: 1840 Folsom Street, Suite 302, Boulder, CO, 80302:: All rights reserved</p>

          </div>
          <div className='last_part '>
            <h2 className='text-white'>Luckyland : part of VGW family</h2>
            <img src="./images/logos.png " className='alllogos' />
          </div>


        </div>


      </div>
      <div className='footer_div'>
<div className=''>

  <p>Copyright 2019 LuckyLand Slots - All Rights Reserved -
    <a href="/">Terms of Services</a> - <a href="/">Privacy Policy</a>  - <a href="/">Contact Us</a> <br/>
No Purchase necessary subject to Terms of Service, void where prohibited by law.</p>

</div>

<div className=''>

<ion-icon style={{color:"#fff" , 
fontSize:"23px",
  padding: "5px",
  borderRadius: "10px ",
  backgroundColor: "#336",marginLeft:"10px"}} name="logo-facebook" > </ion-icon> 
<ion-icon style={{color:"#fff"  ,
fontSize:"23px",
  padding: "5px",
  borderRadius: "10px ",
  backgroundColor: "#059BE5" ,marginLeft:"10px"}}  name="logo-twitter"></ion-icon>
</div>
  </div>

      
    </>
  )
}

export default Landlord